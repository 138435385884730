type NotificationType = "info" | "warning" | "success" | "danger";

/**
 * @private
 */
export type NotificationOptions = {
  /**
   * @private
   */
  type?: NotificationType;
  timeout?: number;
  persistent?: boolean;
  link?: string;
};

export function useNotificationsEx() {
  const _notifications: any = inject(
    "swNotifications",
    ref([])
  );

  provide("swNotifications", _notifications);

  function removeOne(notificationId: any) {
    _notifications.value = _notifications.value?.filter(({ id }) => id !== notificationId) || [];
  }

  function removeAll() {
    _notifications.value = [];
  }

  function geterateId() {
    return (/* @__PURE__ */ new Date()).getTime();
  }

  async function pushNotification(message: string, options: any) {
    const timeout = options.timeout || 5000;
    const persistent = !!options.persistent;
    _notifications.value = _notifications.value || [];
    const messageId = geterateId();
    _notifications.value.push({
      id: messageId,
      type: options.type || "info",
      link: options.link,
      message
    });
    if (!persistent) {
      setTimeout(() => {
        removeOne(messageId);
      }, timeout);
    }
  }
  return {
    removeOne,
    removeAll,
    pushInfo: (message: string, options = {}) => pushNotification(message, { ...options, type: "info" }),
    pushSuccess: (message: string, options = {}) => pushNotification(message, { ...options, type: "success" }),
    pushWarning: (message: string, options = {}) => pushNotification(message, { ...options, type: "warning" }),
    pushError: (message: string, options = {}) => pushNotification(message, { ...options, type: "danger" }),
    notifications: computed(() => _notifications.value || [])
  };
}